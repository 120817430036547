<template>
  <CRow>
    <CCol col="12">
      <!-- <CCard>
        <CCardHeader>我的会员</CCardHeader>
        <CCardBody>
          <CListGroup>
            <CListGroupItem v-for="plan in items" :key="plan.id">
              {{ plan.product.name }}

              {{ plan.quantity }}

              {{ plan.current_period_end }} {{ plan.days_until_due }}
            </CListGroupItem>
          </CListGroup>
        </CCardBody>
      </CCard> -->

      <!-- <CCard>
        <CCardHeader>我的使用配额</CCardHeader>
        <CCardBody>
          <table class="table table-sm">
            <tbody>
              <tr>
                <td scope="row">域名数</td>
                <td>{{ quotas.DOMAIN }}</td>
              </tr>
            </tbody>
          </table>
          {{ quotas }}
        </CCardBody>
      </CCard> -->

      <CAlert show color="info" v-if="agent && agent.return_rate">
              我的代理： <CBadge color="primary">返佣： {{ agent.return_rate }}%</CBadge>
      </CAlert>
    </CCol>
    <CCol col="12">
      <CCard>
        <CCardBody>
          <div class="embed-responsive embed-responsive-4by3" >
            <iframe class="embed-responsive-item" :src="userInfoSrc" allowfullscreen></iframe>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "@/plugins/axios.js";

export default {
  name: 'UserInfo',
  data() {
    return {
      items: [],
      quotas: {},
      agent: {},
      userCloark: { "auth-server-url": "", "realm": ""},
    }
  },
  computed: {
    userInfoSrc: function(){
      if (this.userCloark["auth-server-url"] != "") {
        return this.userCloark["auth-server-url"] + "realms/" +  this.userCloark["realm"] + "/account/";
      }
      return "about:_blank"
    }
  },
  mounted() {
    // this.selectedDomain = newDomain;
    this.fetchData();
  },
  methods: {
    fetchData() {
      
      this.listLoading = true;

      axios
        .get('//'+ document.domain +'/statics/keycloak_'+ document.domain +'.json',)
        .then(response => {
          debugger;
          this.userCloark = response.data;
        })

        axios
        .get(`plan/agent/`, {
          params: {
          },
          urlParams: {
          }
        })
        .then(response => {
          this.agent = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (this.listLoading = false));
    }
  }
}
</script>

<style scoped>
.embed-responsive iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>

